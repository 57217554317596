import type { NullablePartial } from '@/types/common';

import { updateSearchParams } from '@/ducks/routes/history';
import { rootStore } from '@/store';

import { config } from './config';
import { FILTER_KEY, type FilterSetter, type FiltersKey, type FiltersTypes } from './types';
import { getSearchParamsKeys, getSearchParamsValue, getSerializedValue } from './utils';

export const SKIP_ROUTER_FILTERS = [FILTER_KEY.priceType, FILTER_KEY.sortType];

const createSetter =
  <T extends keyof FiltersTypes>(key: T): FilterSetter<T> =>
  (value) =>
    setFilters({ [key]: value } as Partial<FiltersTypes>);

const createSetters = () => {
  const setters = {} as { [T in FiltersKey]: FilterSetter<T> };
  for (const e in FILTER_KEY) {
    const key = e as FiltersKey;
    setters[key] = createSetter(key);
  }
  return setters;
};

export const resetFilters = (keys: FiltersKey | FiltersKey[]) =>
  setFilters((Array.isArray(keys) ? keys : [keys]).reduce((values, key) => ({ ...values, [key]: null }), {}));

export const resetAllFilters = () => {
  resetFilters((Object.keys(FILTER_KEY) as FiltersKey[]).filter((item) => isNaN(Number(item))));
};

/**
 * WARNING! This function should be triggered only on users' action
 */
export const setFilters = (values: NullablePartial<FiltersTypes>): URLSearchParams => {
  const searchParamsUpdater = (searchParams: URLSearchParams) => {
    const state = rootStore!.getState();
    (Object.keys(values) as FiltersKey[]).forEach((key) => {
      const { isReadOnly } = config[key] || {};
      const value = values[key] ?? null;
      const serializedValue = getSerializedValue({ key, searchParams, state, value });
      const readonly = typeof isReadOnly === 'function' && isReadOnly(getSearchParamsValue(key, searchParams));

      if (!readonly) {
        getSearchParamsKeys(key).forEach((key) => searchParams.delete(key));
        if (serializedValue) {
          searchParams.set(getSearchParamsKeys(key)[0]!, serializedValue.toString());
        }
      }
    });
  };

  return updateSearchParams({
    asHistory: Object.keys(values).every((key) => SKIP_ROUTER_FILTERS.includes(key)),
    searchParamsUpdater,
  })!;
};

export const setFilter = createSetters();

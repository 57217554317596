import React from 'react';

import Button from '@/components/elements/Button';
import UIResource from '@/components/UIResource';

import Flyout from '../Flyout';

import './DisabledSailorPopup.scss';

type DisabledSailorPopupProps = {
  onDismissClick: () => void;
  onSailorsericeBtnClick: React.MouseEventHandler;
  showModal: boolean;
  tabIndex: number;
};

const DisabledSailorPopup = ({
  onDismissClick,
  onSailorsericeBtnClick,
  showModal,
  tabIndex,
}: DisabledSailorPopupProps) => (
  <Flyout className="DisabledSailorModal" direction="modal" onDismiss={onDismissClick} open={showModal}>
    <div className="DisabledSailorModal__container">
      <h1 className="DisabledSailorModal__heading">
        <UIResource id="MNVV.OneSailorError.heading" />
      </h1>
      <p className="DisabledSailorModal__subHeading">
        <UIResource id="MNVV.OneSailorError.body" />
      </p>
      <div className="DisabledSailorModal__footer">
        <div className="SailorServiceCta">
          <Button className="fullwidth" onClick={onSailorsericeBtnClick} tabIndex={tabIndex} variant="secondary">
            <UIResource id="MNVV.OneSailorError.PrimaryCTA" />
          </Button>
        </div>
        <div className="CloseBtnCta">
          <Button className="fullwidth" onClick={onDismissClick} tabIndex={tabIndex} variant="secondary">
            <UIResource id="MNVV.OneSailorError.SecondaryCTA" />
          </Button>
        </div>
      </div>
    </div>
  </Flyout>
);

export default DisabledSailorPopup;

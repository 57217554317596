import cn from 'classnames';

type Props = {
  children?: React.ReactNode;
  className?: string;
  legend?: string;
  role?: string;
  tabIndex?: number;
};

const FormGroup = ({ children, className, id, legend, role, tabIndex = 0 }: Props) => {
  const groupLabelId = id ? `${id}_label` : undefined;

  return (
    <fieldset
      aria-labelledby={groupLabelId}
      className={cn('form-group', className)}
      id={id}
      role={role}
      tabIndex={tabIndex}
    >
      {legend && <legend id={groupLabelId}>{legend}</legend>}
      {children}
    </fieldset>
  );
};

export default FormGroup;
